import React from 'react';
import { Star, Calendar, Phone } from 'lucide-react';

export const ExpertCard = ({ expert, onSelect }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow p-4 border border-gray-100">
      <div className="flex items-center gap-4">
        {/* Placeholder image for experts */}
        {/*         <div className="w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center">
          <span className="text-xl font-bold text-gray-500">
            {expert.prenom_expert[0]}
          </span>
        </div> */}

        <div className="w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center">
          {expert.photo_expert ? (
            <img
              src={`data:image/jpeg;base64,${expert.photo_expert}`}
              alt={`${expert.prenom_expert} ${expert.nom_expert}`}
              className="w-full h-full rounded-full object-cover"
            />
          ) : (
            <span className="text-xl font-bold text-gray-500">
              {expert.prenom_expert[0]}
            </span>
          )}
        </div>

        <div className="flex-1">
          {/* Expert name and title */}
          <h3 className="font-semibold text-gray-900">
            {expert.prenom_expert} {expert.nom_expert}
          </h3>
          <p className="text-sm text-gray-600">{expert.titre_expert}</p>
          {/* Rating */}
          <div className="flex items-center gap-2 mt-1">
            <Star className="w-4 h-4 text-yellow-400 fill-current" />
            <span className="text-sm text-gray-600">
              {parseFloat(expert.moyenne_note) > 0
                ? parseFloat(expert.moyenne_note).toFixed(1)
                : "N/A"}            
            </span>
          </div>
        </div>
      </div>

      {/* Additional details
      <div className="mt-4 text-sm text-gray-600">
        <p>{expert.description_expert}</p>
      </div> */}

      {/*<div className="mt-4 flex items-center justify-between text-sm text-gray-600">
        <div className="flex items-center gap-1">
          <Calendar className="w-4 h-4" />
          <span>
            {new Date(expert.date_creation_exp_srv).toLocaleDateString('fr-FR')}
          </span>
        </div>
        <div className="flex items-center gap-1">
          <Phone className="w-4 h-4" />
          <span>{expert.tel_expert}</span>
        </div>
      </div> */}

      {/* Book Consultation Button */}
      <button
        onClick={() => onSelect(expert)}
        className="mt-4 w-full bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 transition-colors"
      >
        Réserver une consultation
      </button>
    </div>
  );
};


/*import React from 'react';
import { Star, Calendar, DollarSign } from 'lucide-react';

export const ExpertCard = ({ expert, onSelect }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow p-4 border border-gray-100">
      <div className="flex items-center gap-4">
        <img
          src={expert.imageUrl}
          alt={expert.name}
          className="w-16 h-16 rounded-full object-cover"
        />
        <div className="flex-1">
          <h3 className="font-semibold text-gray-900">{expert.name}</h3>
          <p className="text-sm text-gray-600">{expert.specialty}</p>
          <div className="flex items-center gap-2 mt-1">
            <Star className="w-4 h-4 text-yellow-400 fill-current" />
            <span className="text-sm text-gray-600">{expert.rating.toFixed(1)}</span>
          </div>
        </div>
      </div>
      
      <div className="mt-4 flex items-center justify-between text-sm text-gray-600">
        <div className="flex items-center gap-1">
          <Calendar className="w-4 h-4" />
          <span>{expert.availability}</span>
        </div>
        <div className="flex items-center gap-1">
          <DollarSign className="w-4 h-4" />
          <span>${expert.price}/hour</span>
        </div>
      </div>
      
      <button
        onClick={() => onSelect(expert)}
        className="mt-4 w-full bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 transition-colors"
      >
        Book Consultation
      </button>
    </div>
  );
}; */