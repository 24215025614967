import React from 'react';
import { format } from 'date-fns';
import { Bot, User } from 'lucide-react';
import ReactMarkdown from 'react-markdown';

export const ChatMessage = ({ message }) => {
  const isBot = message.sender === 'bot';

  return (
    <div className={`flex gap-3 ${isBot ? 'flex-row' : 'flex-row-reverse'}`}>
      <div className={`flex-shrink-0 h-10 w-10 rounded-full flex items-center justify-center ${isBot ? 'bg-green-100' : 'bg-gray-100'}`}>
        {isBot ? <Bot className="h-6 w-6 text-green-600" /> : <User className="h-6 w-6 text-gray-600" />}
      </div>
      <div className={`flex flex-col max-w-[80%] ${isBot ? 'items-start' : 'items-end'}`}>
        <div className={`rounded-2xl px-4 py-2 ${isBot ? 'bg-green-50 text-gray-800' : 'bg-green-600 text-white'}`}>
          {/* Rendu Markdown pour le contenu */}
          <ReactMarkdown className="text-sm">{message.content}</ReactMarkdown>
        </div>
        <span className="text-xs text-gray-500 mt-1">
          {format(message.timestamp, 'HH:mm')}
        </span>
      </div>
    </div>
  );
};


/*
export const ChatMessage = ({ message }) => {
  const isBot = message.sender === 'bot';

  return (
    <div className={`flex gap-3 ${isBot ? 'flex-row' : 'flex-row-reverse'}`}>
      <div className={`flex-shrink-0 h-10 w-10 rounded-full flex items-center justify-center ${isBot ? 'bg-green-100' : 'bg-gray-100'}`}>
        {isBot ? <Bot className="h-6 w-6 text-green-600" /> : <User className="h-6 w-6 text-gray-600" />}
      </div>
      <div className={`flex flex-col max-w-[80%] ${isBot ? 'items-start' : 'items-end'}`}>
        <div className={`rounded-2xl px-4 py-2 ${isBot ? 'bg-green-50 text-gray-800' : 'bg-green-600 text-white'}`}>
          <p className="text-sm">{message.content}</p>
        </div>
        <span className="text-xs text-gray-500 mt-1">
          {format(message.timestamp, 'HH:mm')}
        </span>
      </div>
    </div>
  );
}; */