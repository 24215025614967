import React, { Suspense, lazy } from 'react';
import { Helmet } from "react-helmet";
import ChatBoot from '../ChatBot/ChatBot';

// Chargement lazy de chaque composant
const Header = lazy(() => import('./Header'));
const NosServices = lazy(() => import('./NosServices'));
const NosExperts = lazy(() => import('./NosExperts'));
const Commentfonctionne = lazy(() => import('./Commentfonctionne'));
const CardCarousel = lazy(() => import('./CardCarousel'));
const FooterBar = lazy(() => import('./FooterBar'));

export default function Accueil() {
    return (
        <div>
            {/* Utilisation de Suspense pour gérer le chargement */}
            <Helmet>
                <title>Accueil - Les-experts.ma</title>
            </Helmet>
            <Suspense>
                <Header />
            </Suspense>

            <Suspense>
                <NosServices />
            </Suspense>

            <Suspense>
                <NosExperts />
            </Suspense>

            <Suspense>
                <Commentfonctionne />
            </Suspense>

            <Suspense>
                <CardCarousel />
            </Suspense>

            <Suspense>
                <FooterBar />
            </Suspense>
            <ChatBoot />
        </div>
    );
}
